<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: 'tickets' })"
                    >Liste des tickets</vk-breadcrumb-item
                >
                <vk-breadcrumb-item :disabled="true">Ticket {{ $route.params.ticketId }}</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <div class="d-flex align-items-center" v-if="ticketIsUpdating">
                <strong>Chargement en cours du ticket {{ $route.params.ticketId }}...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
            <Ticket :ticket="ticket" v-else />
        </div>
    </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import Ticket from '@/components/Ticket'
import { mapState } from 'vuex'

const enterUpdateGuard = (to, from, next) => {
    if (
        store.state.login.user.userData.accountType === 'workerAccount' &&
        store.state.login.user.userData.roles.includes('admin')
    ) {
        store.dispatch('users/updateUsersList')
    }

    store.dispatch('tickets/setCurrentTicket', to.params.ticketId).catch(() => {
        store.dispatch('notifications/addNotification', { status: 'warning', message: 'Échec de chargement du ticket' })
        router.push({ name: 'tickets' })
    })

    next()
}

export default {
    name: 'TicketView',
    components: {
        Ticket,
    },
    data: () => ({}),
    computed: {
        ...mapState('tickets', { ticket: 'currentTicket', ticketIsUpdating: 'currentTicketUpdateIsPending' }),
    },
    beforeRouteEnter: enterUpdateGuard,
    beforeRouteUpdate: enterUpdateGuard,
    beforeRouteLeave(to, from, next) {
        store.dispatch('tickets/reset')
        next()
    },
}
</script>

<style scoped></style>
